import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Button,
  TextField,
} from '@mui/material';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const SUBMIT_ONBOARDING = gql`
  mutation submitOnboarding($input: OnboardingInput!) {
    submitOnboarding(input: $input) {
      success
    }
  }
`;

const Onboarding = ({ onComplete }) => {
  const [submitOnboarding] = useMutation(SUBMIT_ONBOARDING);
  const [formState, setFormState] = useState({
    givenName: null,
    familyName: null,
  });

  const setStateProp = (prop) => (e) => {
    setFormState({
      ...formState,
      [prop]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    const input = {
      profile: formState,
    };

    e.preventDefault();

    try {
      await submitOnboarding({ variables: { input }});
      onComplete();
    } catch (e) {
      throw e;
    }
  };

  return (
    <Grid>
      <Grid>
        <Grid>
          <Typography use="headline6">Complete Profile</Typography>
        </Grid>
      </Grid>
      <Grid>
        <Grid>
          <form onSubmit={handleSubmit}>
            <TextField outlined required label="First Name" type="text" onChange={setStateProp('givenName')} />
            <TextField outlined required label="Last Name" type="text" onChange={setStateProp('familyName')} />
            <div>
              <Button label="Submit" />
            </div>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Onboarding;
