import React from 'react';
import { Auth } from 'aws-amplify';

export const UserContext = React.createContext(null);

const formatCognitoAuthUser = o => {
  const {
    email,
    sub: id,
    given_name: givenName,
    family_name: familyName,
  } = o.attributes;

  return {
    id,
    email,
    givenName,
    familyName,
  };
}

export const UserProvider = ({ children }) => {
  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => setUser(formatCognitoAuthUser(user)))
      .catch(() => setUser(null));
  }, []);

  const signIn = (usernameOrEmail, password) =>
    Auth.signIn(usernameOrEmail, password)
      .then(cognitoUser => {
        setUser(formatCognitoAuthUser(cognitoUser));
        return cognitoUser;
      })
      .catch((err) => {
        if (err.code === 'UserNotFoundException') {
          err.message = 'Invalid username or password';
        }

        throw err;
      });

  const signUp = ({
    email,
    password,
  }) =>
    Auth.signUp({
      password,
      username: email,
      attributes: {
        email,
      }
    })
      //.then(cognitoUser => {
      //  setUser(cognitoUser);
      //  return cognitoUser;
      //})
      .catch((err) => {
        if (err.code === 'UserNotFoundException') {
          err.message = 'Invalid username or password';
        }

        throw err;
      });

  const signOut = () =>
    Auth.signOut().then(data => {
      setUser(null);
      return data;
    });

  const values = React.useMemo(() => ({ user, signIn, signUp, signOut }), [user]);

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = React.useContext(UserContext);

  if (context === undefined) {
    throw new Error('`useUser` hook must be used within a `UserProvider` component');
  }

  return context;
};
