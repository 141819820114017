import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
} from 'react-router-dom';
import {
  generatePath,
} from 'react-router';
import Roadblock from './Roadblock';
import Home from './Home';
import UserHome from './UserHome';
import Signup from './Signup';
import SignIn from './Signin';
import CreateSite from './CreateSite';
import SiteDashboard from './SiteDashboard';
// import CreateSiteInvitation from './CreateSiteInvitation';
import CssBaseline from '@mui/material/CssBaseline';
import LoggedInLayout from './LoggedInLayout';
import SiteHeader from './SiteHeader';
import SiteUsers from './SiteUsers';
import GlobalFooter from './GlobalFooter';
import Container from './Container';
import { useUser } from './user';
import routes from './routes';

const PrivateRoute = ({ children, ...rest }) => {
  const { user } = useUser();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/sign-in",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

const SiteRouter = () => {
  const { path } = useRouteMatch();

  return (
     <>
      <SiteHeader />
      <Switch>
        <Route exact path={path}>
          {({ match }) => <Redirect to={generatePath(routes.site.dashboard, { id: match.params.id })} />}
        </Route>
        <Route exact path={routes.site.dashboard}>
          <SiteDashboard />
        </Route>
        <Route exact path={routes.site.users}>
          <SiteUsers />
        </Route>
      </Switch>
    </>
  );
};

function App() {
  return (
    <Router>
      <CssBaseline />
      <GlobalFooter />
      <Roadblock>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/sign-in">
            <SignIn />
          </Route>
          <Route path="/sign-up">
            <Signup />
          </Route>
          <PrivateRoute>
            <LoggedInLayout>
              <Switch>
                <Route exact path="/home">
                  <UserHome />
                </Route>
                <Route path={routes.site.detail}>
                  <SiteRouter />
                </Route>
              </Switch>
            </LoggedInLayout>
          </PrivateRoute>
        </Switch>
      </Roadblock>
    </Router>
  );
}

export default App;
