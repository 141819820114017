import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  CardActionArea,
  LinearProgress,
  CardActions,
} from '@mui/material';
import Container from './Container';
import CreateSite from './CreateSite';

import { useUser } from './user';

const SUBMIT_ACCEPT_SITE_INVITATION = gql`
  mutation acceptSiteInvitation($input: AcceptSiteInvitationInput!) {
    acceptSiteInvitation(input: $input) {
      invitation {
        acceptedAt
      }
    }
  }
`;

const GET_USER = gql`
  query viewer {
    viewer {
      siteInvitations {
        edges {
          node {
            id
            site {
              name
              streetAddress
              addressRegion
              addressLocality
            }
          }
        }
      }
      sites {
        edges {
          node {
            id
            name
            streetAddress
            addressRegion
            addressLocality
            device {
              id
            }
          }
        }
      }
    }
  }
`;

const SiteInvitationCard = ({ onAcceptInvitation, invitation }) => {
  const { site } = invitation;

  return (
    <Card>
      <CardContent>
        <Typography variant="overline">Invitation</Typography>
        <Typography gutterBottom variant="h6" component="div">
          {site.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
           {site.streetAddress} {site.addressLocality}, {site.addressRegion}
        </Typography>
      </CardContent>
      <CardActions>
        <Grid container justifyContent="flex-end">
          <Button onClick={onAcceptInvitation} variant="outlined">Accept</Button>
        </Grid>
      </CardActions>
    </Card>
  );
};

const SiteCard = ({ site }) => (
  <Card>
    <CardActionArea to={{
      pathname: `/sites/${site.id}/dashboard`,
      state: { site },
    }} component={Link}>
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {site.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
           {site.streetAddress} {site.addressLocality}, {site.addressRegion}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
);

const EmptySitesListMessage = ({ onCreateSiteClick }) => (
  <Card>
    <CardContent>
      <Grid container>
        <Grid item xs={7}>
          <Typography variant="h5">
            You don’t have any sites.
          </Typography>
          <Typography variant="body2">
            Add your first site now.
          </Typography>
        </Grid>
        <Grid item style={{ lineHeight: '50px' }} >
          <Button variant="contained" onClick={onCreateSiteClick}>Create Site</Button>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

const Home = () => {
  const { user } = useUser();
  const [createSiteDialogOpen, setCreateSiteDialogOpen] = useState(false);
  const [
    submitAcceptSiteInvitation,
    { data: accepSitetInvitationData, loading: acceptingSiteInvitationLoading, error: acceptSiteInvitationError }
  ] = useMutation(SUBMIT_ACCEPT_SITE_INVITATION);
  const { loading, data, error, refetch: getUser } = useQuery(GET_USER, {
    skip: !user,
  });

  const acceptSiteInvitation = async ({ id: invitationId }) => {
    const input = { invitationId };
    await submitAcceptSiteInvitation({ variables: { input }});
    getUser();
  };

  const userSites = data && data.viewer
    ? data.viewer.sites.edges
    : [];
  const userSiteIvitations = data && data.viewer
    ? data.viewer.siteInvitations.edges
    : [];

  if (user && !loading && !data && !error) getUser();

  return (
    <>
      <Helmet>
        <title>GAS360 - Home</title>
      </Helmet>
      <div style={{ padding: '25px 0' }}>
        <Dialog maxWidth='md' onClose={() => setCreateSiteDialogOpen(false)} open={createSiteDialogOpen}>
          <DialogTitle>Create Site</DialogTitle>
          <DialogContent>
            <CreateSite />
          </DialogContent>
        </Dialog>
        <Container>
          <Grid container>
            <Grid item container spacing={2} style={{ marginBottom: '25px' }}>
              <Grid item>
                <Typography variant="h5">Sites</Typography>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={() => setCreateSiteDialogOpen(true)}>Create Site</Button>
              </Grid>
            </Grid>
            { loading && (
            <Grid item container justifyContent="center">
              <Grid item xs={12}>
                <LinearProgress />
              </Grid>
            </Grid>
            )}

            { !loading && (
            <Grid item container spacing={2}>
            {userSiteIvitations.length > 0 && userSiteIvitations.map(({ node }) => (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <SiteInvitationCard invitation={node} onAcceptInvitation={() => acceptSiteInvitation(node)} />
              </Grid>
            ))}
            </Grid>
            )}

            { !loading && (
            <Grid item container spacing={2}>
              {!userSites.length && !userSiteIvitations.length && (
              <Grid item container xs={12} justifyContent="center">
                <Grid item xs={8}>
                  <EmptySitesListMessage onCreateSiteClick = {() => setCreateSiteDialogOpen(true)} />
                </Grid>
              </Grid>
              )}
            {userSites.length > 0 && userSites.map(({ node }) => (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <SiteCard site={node} />
              </Grid>
            ))}
            </Grid>
            )}
          </Grid>
        </Container>
      </div>
    </>
  );
}

export default Home;

