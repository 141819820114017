import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {
  useLocation,
} from "react-router-dom";
import { useUser } from './user';
import Onboarding from './Onboarding';
const ROADBLOCK = {
  ONBOARDING: 'ONBOARDING',
};

const GET_ROADBLOCK = gql`
  query GetRoadblocks($event: RoadblockEvent!) {
    roadblock(event: $event) {
      type
      externalURL
    }
  }
`;

const Roadblock = ({ children }) => {
  // dev shortcut
  return children;

  const location = useLocation();
  const { user } = useUser();
  const { loading: loadingRoadblock, data: roadblockData = {}, refetch: refetchRoadblock } = useQuery(GET_ROADBLOCK, { variables: { event: 'LOGIN' }, skip: !user });
  const { roadblock } = roadblockData;

  console.log({
    loadingRoadblock, roadblockData });

  const onComplete = () => {
    refetchRoadblock();
  };

  if (loadingRoadblock) return 'ROADBLOCK!';
  if (roadblock && roadblock.type) {
    if (roadblock.type === ROADBLOCK.ONBOARDING) {
      return <Onboarding onComplete={onComplete} />;
    }
  }
  return children;
};

export default Roadblock;
