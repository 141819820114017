import React, { useMemo } from 'react';
import * as R from 'ramda';
import { useQuery } from '@apollo/react-hooks';
import Panel from './Panel';
import PanelTable from './PanelTable';
import { ParentSize } from '@visx/responsive';
import {
  Grid,
} from '@mui/material';
import TimeseriesXYChart, { yAccessors } from './TimeseriesXYChart';
import departments from './departments';
import { GET_DEPT_SALES } from './api';

const sumPointsByMetric = sumAttr => R.pipe(
  R.groupBy(R.prop('metric')),
  R.mapObjIndexed(R.pipe(
    R.pluck(sumAttr),
    R.sum,
  )),
);

const createTableSummary = sumAttr => R.pipe(
  R.pluck('metrics'),
  R.flatten,
  R.groupBy(R.prop('name')),
  R.mapObjIndexed(R.pipe(
    R.pluck(sumAttr),
    R.sum,
    x => x.toFixed(2),
  )),
  R.omit(nonSalesDepartments),
  R.toPairs,
  R.sortBy(([_, x]) => Number(x)),
  R.reverse,
);

const nonSalesDepartments = [
  departments[100],
  departments[9992],
  departments[9710],
];

const filterNonSalesDepartments = R.filter(
  R.where({
    metric: R.pipe(R.includes(R.__, nonSalesDepartments), R.not)
  })
)

const SalesByDepartment = ({
  siteId,
  startDate,
  endDate,
  interval,
}) => {
  const { loading, data = {} } = useQuery(GET_DEPT_SALES, {
    variables: {
      interval,
      siteId,
      startDate: startDate.toISODate(),
      endDate: endDate.toISODate(),
    },
  });

  const { site = {} } = data;
  const { departmentSales = {} } = site;
  const { points = [] } = departmentSales;
  const tableSummary = createTableSummary('quantity')(points);

  return (
    <Panel title="Sales by Department" loading={loading}>
      <div>
        <Grid container>
          <Grid item xs={12} sm={7}>
            <div style={{ height: '300px' }}>
              <ParentSize>
              {parent => (
                <TimeseriesXYChart
                  parent={parent}
                  startDate={startDate}
                  endDate={endDate}
                  interval={interval}
                  points={points}
                  yAccessor={yAccessors.quantity}
                  yAxiesLabel="Quantity"
                />
              )}
              </ParentSize>
            </div>
          </Grid>
          <Grid item xs={12} sm={5}>
            <div style={{ height: '300px' }}>
              <ParentSize>
              {parent => (
                <PanelTable
                  parent={parent}
                  header={['Name', 'Total']}
                  rows={tableSummary}
                />
              )}
              </ParentSize>
            </div>
          </Grid>
        </Grid>
      </div>
    </Panel>
  );
};

export default SalesByDepartment;
