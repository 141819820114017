import React, { useEffect, useRef, useMemo, useCallback, useContext, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import {
  useParams,
  useHistory,
  useLocation,
} from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider as ListDivider,
  Grid,
  LinearProgress,
  Menu,
  MenuItem,
  TextField,
  Typography,
  Link,
} from '@mui/material';
import CalendarToday from '@mui/icons-material/CalendarToday';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DownloadIcon from '@mui/icons-material/Download';
import {
  lightTheme,
  ThemeProvider,
} from '@visx/xychart';
import SalesByDept from './SalesByDepartment';
import SalesByDeptPie from './SalesByDepartmentPie';
import FuelQuantityByGrade from './FuelQuantityByGrade';
import FuelQuantitySingleStat from './FuelQuantitySingleStat';
import DepartmentSalesSingleStat from './DepartmentSalesSingleStat';
import MethodOfPaymentsPie from './MethodOfPaymentsPie';
import Container from './Container';

const PanelsContainer = styled(Grid)`
  position: relative;
  padding: 10px 0;
  background-color: #333;
`;

const DATE_SELECTION = {
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  DAY_BEFORE_YESTERDAY: 'DAY_BEFORE_YESTERDAY',
  CUSTOM: 'CUSTOM',
};

const DURATION = {
  HOUR: 'HOUR',
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
};

const CREATE_SITE_LINK_CODE = gql`
  mutation createSiteLinkCode($input: CreateSiteLinkCodeInput!) {
    createSiteLinkCode(input: $input) {
      linkCode {
        code
      }
    }
  }
`;

const GET_SITE = gql`
  query getSite($siteId: ID!) {
    site(id: $siteId) {
      id
      name
      device {
        id
      }
    }
  }
`;

const DeviceMissingMessage = ({ codeLoading, onGenerateCodeClick }) => (
  <Card>
    <CardContent>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">
            Device setup
          </Typography>
          <Grid container item style={{ marginTop: '10px' }} spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Step 1</Typography>
              <Typography variant="body2">Start by downloading the latest Gas360 installer onto your back office computer. Return to this screen and proceed to Step 2 when prompted for a device link code.</Typography>
              <Button component={Link} startIcon={<DownloadIcon />} variant="outlined" style={{ marginTop: '15px' }} href="https://s3.us-west-2.amazonaws.com/release.gas360.net/pre-release/GAS360-0.1.63-beta-x64.exe">Download</Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Step 2</Typography>
              <Typography variant="body2">Click the button below to generate a link code. You'll enter this into the desktop app when promoted.</Typography>
              <LoadingButton loading={codeLoading} onClick={onGenerateCodeClick} variant="outlined" style={{ marginTop: '15px' }}>Generate Code</LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

const computeStartEndDateFromMenu = (selected) => {
  const today = DateTime.now().startOf('day');
  // eslint-disable-next-line default-case
  switch (selected) {
    case DATE_SELECTION.TODAY:
      return {
        startDate: today,
        endDate: today.plus({ days: 1 }),
      };
    case DATE_SELECTION.YESTERDAY:
      return {
        startDate: today.minus({ days: 1 }),
        endDate: today,
      };
    case DATE_SELECTION.DAY_BEFORE_YESTERDAY:
      return {
        startDate: today.minus({ days: 2 }),
        endDate: today.minus({ days: 1 }),
      };
  };
};

const getInterval = ({ startDate, endDate }) => {
  const { days: diffDays } = endDate.diff(startDate, 'days');

  if (diffDays > 70) {
    return DURATION.MONTH;
  } else if (diffDays > 31) {
    return DURATION.WEEK;
  } else if (diffDays > 2) {
    return DURATION.DAY;
  } else {
    return DURATION.HOUR;
  }
};

const formatDateForInput = d => d.toFormat('yyyy-MM-dd');

const getDateButtonText = (selected, { startDate, endDate }) => {
  // eslint-disable-next-line default-case
  switch (selected) {
    case DATE_SELECTION.TODAY:
      return 'Today';
    case DATE_SELECTION.YESTERDAY:
      return 'Yesterday';
    case DATE_SELECTION.DAY_BEFORE_YESTERDAY:
      return 'Day Before Yesterday';
    case DATE_SELECTION.CUSTOM:
      return `${startDate.toLocaleString(DateTime.DATE_MED)} - ${endDate.toLocaleString(DateTime.DATE_MED)}`;
  }
};

const SiteDashboard = () => {
  const { id } = useParams();
  const history = useHistory;
  const { state = {} } = useLocation();
  const [location, setLocation] = useState(history.location);
  const [menuOpen, setMenuOpen] = useState(false);
  const [codeModalOpen, setCodeModalOpen] = useState(false);
  const [dateSelect, setDateSelect] = useState(DATE_SELECTION.TODAY);
  const [dateRange, setDateRange] = useState(computeStartEndDateFromMenu(dateSelect));
  const interval = getInterval(dateRange);
  const { startDate, endDate } = dateRange;
  const [{ customStartDate, customEndDate }, setCustomDate] = useState({});
  const dateButtonText = getDateButtonText(dateSelect, dateRange);
  const { loading, data: getSiteResult = {} } = useQuery(GET_SITE, {
    skip: state.site,
    variables: {
      siteId: id,
    },
  });
  const [createSiteLinkCode, {
    data: createSiteLinkCodeData,
    loading: createSiteLinkCodeLoading,
    error: createSiteLinkCodeError,
  }] = useMutation(CREATE_SITE_LINK_CODE);
  const site = state.site || getSiteResult.site || {};
  const tz = site.timezone || 'America/Los_Angeles';
  const code = createSiteLinkCodeData ? createSiteLinkCodeData.createSiteLinkCode.linkCode.code : null;
  const queryStartDate = startDate.setZone(tz, { keepLocalTime: true });
  const queryEndDate = endDate.setZone(tz, { keepLocalTime: true });

  console.log({ customStartDate, customEndDate, startDate, endDate });

  const onGenerateCodeClick = async (e) => {
    e.preventDefault();
    await createSiteLinkCode({ variables: { input: { siteId: id }}})
    setCodeModalOpen(true);
  };

  const handleDateMenuSelect = option => (e) => {
    const { startDate, endDate } = computeStartEndDateFromMenu(option);
    setDateSelect(option);
    setDateRange({ startDate, endDate })
    setCustomDate({
      customStartDate: startDate,
      customEndDate: endDate,
    });
  };

  const setStartDate = e => {
    const { value } = e.target;
    setCustomDate({
      customEndDate,
      customStartDate: DateTime.fromJSDate(new Date(value)).startOf('day'),
    });
  };
  const setEndDate = e => {
    const { value } = e.target;
    setCustomDate({
      customStartDate,
      customEndDate: DateTime.fromJSDate(new Date(value)).startOf('day'),
    });
  };
  const handleMenuClose = () => {
    setCustomDate({});
    setMenuOpen(false);
  };
  const handleDateSelectSubmit = (e) => {
    e.preventDefault();
    setDateSelect(DATE_SELECTION.CUSTOM);
    setDateRange({
      startDate: customStartDate || startDate,
      endDate: customEndDate || endDate,
    });
    setMenuOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <Dialog onClose={() => setCodeModalOpen(false)} open={codeModalOpen}>
        <DialogTitle>Device Link Code</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Enter the following code into the Gas360 desktop application.
          </Typography>
          <Typography variant="h2" style={{ textAlign: 'center', marginTop: '10px' }}>
            {code}
          </Typography>
        </DialogContent>
      </Dialog>
      { loading && (
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} style={{ marginTop: '25px' }}>
            <LinearProgress />
          </Grid>
        </Grid>
       </Container>
      )}
      { !loading && !site.device && (
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={10} lg={8} style={{ marginTop: '25px' }}>
              <DeviceMissingMessage codeLoading={createSiteLinkCodeLoading} onGenerateCodeClick={onGenerateCodeClick} code={code} />
            </Grid>
          </Grid>
        </Container>
      )}
      { !loading && site.device && (
      <PanelsContainer>
        <Container>
          <Grid container style={{ marginBottom: '15px' }} justifyContent="flex-end">
            <Grid item>
              <Button variant="contained" onClick={handleClick} startIcon={<CalendarToday />} endIcon={<ArrowDropDownIcon />}>
                {dateButtonText}
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleDateMenuSelect(DATE_SELECTION.TODAY)}>Today</MenuItem>
                <MenuItem onClick={handleDateMenuSelect(DATE_SELECTION.YESTERDAY)}>Yesterday</MenuItem>
                <MenuItem onClick={handleDateMenuSelect(DATE_SELECTION.DAY_BEFORE_YESTERDAY)}>Day before yesterday</MenuItem>
                <ListDivider />
                <form onSubmit={handleDateSelectSubmit} style={{ padding: '5px 15px' }}>
                  <div style={{ marginBottom: '10px' }}>
                    <Typography variant="overline">Custom</Typography>
                  </div>
                  <div style={{ marginBottom: '10px' }}>
                    <TextField label='Start Date' type='date' value={formatDateForInput(customStartDate || startDate)} onChange={setStartDate} />
                  </div>
                  <div style={{ marginBottom: '10px' }}>
                    <TextField label='End Date' type='date' value={formatDateForInput(customEndDate || endDate)} onChange={setEndDate} />
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <Button type="submit" variant="outlined">Apply date range</Button>
                  </div>
                </form>
              </Menu>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FuelQuantitySingleStat siteId={id} interval={interval} startDate={queryStartDate} endDate={queryEndDate} />
            </Grid>
            <Grid item xs={12} md={6}>
              <DepartmentSalesSingleStat siteId={id} interval={interval} startDate={queryStartDate} endDate={queryEndDate} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FuelQuantityByGrade siteId={id} interval={interval} startDate={queryStartDate} endDate={queryEndDate} tz={tz} />
            </Grid>
            <Grid item xs={12} md={6}>
              <SalesByDept siteId={id} interval={interval} startDate={queryStartDate} endDate={queryEndDate} />
            </Grid>
            <Grid item xs={12} md={6}>
              <SalesByDeptPie siteId={id} interval={interval} startDate={queryStartDate} endDate={queryEndDate} />
            </Grid>
            <Grid item xs={12} md={6}>
              <MethodOfPaymentsPie siteId={id} interval={interval} startDate={queryStartDate} endDate={queryEndDate} />
            </Grid>
          </Grid>
        </Container>
      </PanelsContainer>
      )}
    </ThemeProvider>
  );
}

/*
 *
          <Grid container spacing={2}>
        /*
            <Grid item xs={12} md={6}>
              <FuelQuantitySingleStat siteId={id} interval={interval} startDate={queryStartDate} endDate={queryEndDate} />
            </Grid>
            <Grid item xs={12} md={6}>
              <DepartmentSalesSingleStat siteId={id} interval={interval} startDate={queryStartDate} endDate={queryEndDate} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FuelQuantityByGrade siteId={id} interval={interval} startDate={queryStartDate} endDate={queryEndDate} tz={tz} />
            </Grid>
            <Grid item xs={12} md={6}>
              <SalesByDept siteId={id} interval={interval} startDate={queryStartDate} endDate={queryEndDate} />
            </Grid>
            <Grid item xs={12} md={6}>
              <SalesByDeptPie siteId={id} interval={interval} startDate={queryStartDate} endDate={queryEndDate} />
            </Grid>
          </Grid>
 * */

export default SiteDashboard;
