import React from 'react';
import GlobalHeader from './GlobalHeader';

const LoggedInLayout = ({ children }) => (
  <>
    <GlobalHeader />
    {children}
  </>
);

export default LoggedInLayout;
