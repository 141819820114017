import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { scaleOrdinal } from '@visx/scale';
import { Group } from '@visx/group';
import { localPoint } from '@visx/event';
import { useTooltip, TooltipWithBounds } from '@visx/tooltip';
import Pie from '@visx/shape/lib/shapes/Pie';

const range = [
  'rgba(0,76,109,1)',
  'rgba(37,94,126,1)',
  'rgba(61,112,143,1)',
  'rgba(83,131,161,1)',
  'rgba(105,150,179,1)',
  'rgba(127,170,198,1)',
  'rgba(148,190,217,1)',
  'rgba(171,210,236,1)',
  'rgba(193,231,255,1)',
];

export const unitFormatter = {
  money: x => `$${x.toFixed(2)}`,
  gallons: x => `${x.toFixed(2)} gal`,
};

const sumMetricPoints = prop => R.pipe(
  R.pluck('metrics'),
  R.flatten,
  R.groupBy(R.prop('name')),
  R.map(R.pipe(
    R.pluck(prop),
    R.sum,
    R.objOf('sum'),
  ))
);

const calcualteMetricFrequency = R.converge(
  (pieSum, data) => R.map(
    ({ sum }) => ({
      sum,
      freq: (sum / pieSum) * 100,
    })
  )(data),
  [
    R.pipe(R.values, R.pluck('sum'), R.sum),
    R.identity,
  ]
);

const foldInMetric = R.pipe(
  R.mapObjIndexed((value, metric) => ({ ...value, metric})),
  R.values,
);

const createPieData = prop => R.pipe(
  sumMetricPoints(prop),
  calcualteMetricFrequency,
  foldInMetric,
);

const TimeseriesPieChart = ({
  parent,
  points,
  unitFormat = R.identity,
}) => {
  const [data, setData] = useState([]);
  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipTop,
    tooltipLeft,
    tooltipData,
  } = useTooltip({ detectBounds: true });
  const { height, width } = parent;
  const margin = { top: 10, right: 10, bottom: 10, left: 10 };
  const innerWidth = parent.width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const top = centerY + margin.top;
  const left = centerX + margin.left;
  const pieSortValues = (a, b) => b - a;

  useEffect(() => {
    setData(createPieData('total')(points));
  }, [points]);

  const domain = R.pipe(
    R.sortBy(R.prop('value')),
    R.pluck('metric'),
  )(data);

  const getColor = scaleOrdinal({
    domain,
    //range: [
    //  "rgba(93,30,91,1)",
    //  "rgba(93,30,91,0.8)",
    //  "rgba(93,30,91,0.6)",
    //  "rgba(93,30,91,0.4)"
    //]
    //range: defaultColors,
    range,
  });

  return (
    <div style={{ position: 'relative' }}>
      <svg width={width} height={height}>
        <Group top={top} left={left}>
          <Pie
            data={data}
            pieValue={R.prop('freq')}
            pieSortValues={pieSortValues}
            outerRadius={radius}
          >
            {(pie) => {
              return pie.arcs.map((arc, index) => {
                const { metric, sum, freq } = arc.data;
                const [centroidX, centroidY] = pie.path.centroid(arc);
                const arcPath = pie.path(arc);
                const arcFill = getColor(metric);
                return (
                  <g key={`arc-${metric}-${index}`}>
                    <path
                      d={arcPath}
                      fill={arcFill}
                      onMouseEnter={(event) => {
                        const coords = localPoint(event.target.ownerSVGElement, event);
                        showTooltip({
                          tooltipLeft: coords.x,
                          tooltipTop: coords.y,
                          tooltipData: { metric, sum, freq },
                        });
                      }}
                      onMouseLeave={(event) => {
                        hideTooltip();
                      }}
                    />
                  </g>
                );
              });
            }}
          </Pie>
        </Group>
      </svg>
    {tooltipOpen && (
      <div>
        <TooltipWithBounds
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
        >
          {tooltipData.metric}<br />
          {`${unitFormat(tooltipData.sum)} (${tooltipData.freq.toFixed(2)}%)`}
        </TooltipWithBounds>
      </div>
    )}
    </div>
  );
};

export default TimeseriesPieChart;
