import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { curveNatural } from '@visx/curve';
import { AreaClosed } from '@visx/shape';
import { Text } from '@visx/text';
import { scaleTime, scaleLinear } from '@visx/scale';
import { LinearGradient } from '@visx/gradient';
import { max, extent } from 'd3-array';

import {
  GradientOrangeRed,
  GradientTealBlue,
} from '@visx/gradient';

export const unitFormatter = {
  money: x => `$${x.toFixed(2)}`,
  gallons: x => `${x.toFixed(2)} gal`,
};

const sumMetricsByDate = R.pipe(
  R.groupBy(R.prop('date')),
  R.map(R.pipe(
    R.pluck('metrics'),
    R.flatten,
    R.pluck('quantity'),
    R.sum,
  )),
  R.mapObjIndexed((sum, date) => ({ date, sum })),
  R.values,
);

export const background = '#3b6978';
export const background2 = '#204051';
export const accentColor = '#edffea';
export const accentColorDark = '#75daad';

const TimeseriesSingleStat = ({
  parent,
  points,
  total,
  unitFormat = R.identity,
}) => {
  const { width, height } = parent;
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(sumMetricsByDate(points));
  }, [points]);

  const dateScale = scaleTime({
    range: [0, width],
    domain: extent(data, o => new Date(o.date)),
  });

  const sumScale = scaleLinear({
    range: [height, 0],
    domain: [0, (max(data, o => o.sum) || 0) + height/ 3],
    nice: true,
  });

  return (
    <svg width={width} height={height}>
      <rect
        x={0}
        y={0}
        width={width}
        height={height}
        fill="#e2d3fc"
        rx={14}
      />
      <GradientOrangeRed id="GradientOrangeRed" />
      <GradientTealBlue id="GradientTealBlue" />
      <LinearGradient id="area-background-gradient" from={background} to={background2} />
      <LinearGradient id="area-gradient" from={accentColor} to={accentColor} toOpacity={0.1} />
      <AreaClosed
        data={data}
        x={o => dateScale(new Date(o.date))}
        y={o => sumScale(o.sum)}
        yScale={dateScale}
        stroke="transparent"
        fill="#FFF"
        curve={curveNatural}
      />
      <Text
        verticalAnchor="middle"
        textAnchor="middle"
        scaleToFit={true}
        fontFamily="Roboto"
        fontWeight="bold"
        fill="#6200ee"
        width={parent.width * 0.50}
        dx="50%"
        dy="50%"
      >
        {unitFormat(total)}
      </Text>
    </svg>
  );
};

export default TimeseriesSingleStat;
