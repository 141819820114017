import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import * as R from 'ramda';
import { useQuery } from '@apollo/react-hooks';
import Panel from './Panel';
import PanelTable from './PanelTable';
import { ParentSize } from '@visx/responsive';
import {
  Grid,
} from '@mui/material';
import TimeseriesXYChart, { unitFormatter, yAccessors } from './TimeseriesXYChart';
import { GET_FUEL_SALES } from './api';

const createTableSummary = sumAttr => R.pipe(
  R.pluck('metrics'),
  R.flatten,
  R.groupBy(R.prop('name')),
  R.mapObjIndexed(R.pipe(
    R.pluck(sumAttr),
    R.sum,
    x => x.toFixed(2),
  )),
  R.toPairs,
  R.sortBy(([_, x]) => Number(x)),
  R.reverse,
  R.map(([k, v]) => ([k, `${v} gal`])),
);

const FuelQuantityByGrade = ({
  siteId,
  startDate,
  endDate,
  interval,
  tz,
}) => {
  const [tableSummary, setTableSummary] = useState([]);
  const { loading, data = {} } = useQuery(GET_FUEL_SALES , {
    variables: {
      siteId,
      interval,
      startDate: startDate.toISODate(),
      endDate: endDate.toISODate(),
    },
  });

  useEffect(() => {
    if (loading) return;
    const { points = [] } = data.site.fuelSales;
    setTableSummary(createTableSummary('quantity')(points));
  }, [loading, data]);

  const { site = {} } = data;
  const { fuelSales = {} } = site;
  const { points = [] } = fuelSales;

  return (
    <Panel title="Fuel Sales By Grade" loading={loading}>
      <div>
        <Grid container>
          <Grid item xs={12} sm={7}>
            <div style={{ height: '300px' }}>
              <ParentSize>
              {parent => (
                <TimeseriesXYChart
                  parent={parent}
                  startDate={startDate}
                  endDate={endDate}
                  interval={interval}
                  tz={tz}
                  points={points}
                  unitFormat={unitFormatter.gallons}
                  yAxiesLabel="Gallons"
                  yAccessor={yAccessors.quantity}
                />
              )}
              </ParentSize>
            </div>
          </Grid>
          <Grid item xs={12} sm={5}>
            <ParentSize>
              {parent => (
                <div style={{ width: parent.width, height: '300px' }}>
                  <PanelTable
                    parent={parent}
                    header={['Name', 'Total']}
                    rows={tableSummary}
                  />
                </div>
              )}
            </ParentSize>
          </Grid>
        </Grid>
      </div>
    </Panel>
  );
};

export default FuelQuantityByGrade;
