import React from 'react';
import styled from '@emotion/styled';
import {
  Avatar,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  CardActionArea,
  Link as MUILink,
} from '@mui/material';
import { Link } from 'react-router-dom';
import Container from './Container';

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  line-height: 34px;
  color: #777;
`;

const GlobalFooter = () => (
  <Footer>
    <Container>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item>
          <Typography variant="caption">&copy; 2022 GAS360 LLC</Typography>
        </Grid>
      </Grid>
    </Container>
  </Footer>
);

export default GlobalFooter;
