import React, { useEffect } from 'react';
import { useUser } from './user';

const TAG_ID = process.env.REACT_APP_G_MEASUREMENT_ID;

const Analytics = ({ children }) => {
  const { user } = useUser();

  useEffect(() => {
    const params = user ? { user_id: user.id } : {};

    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', TAG_ID, params);
  }, [user]);

  return children;
};

export default Analytics;
