import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import {
  Grid,
  Typography,
  Button,
} from '@mui/material';
import { ReactComponent as LogoFull } from './assets/logo_full_light.svg';

const Main = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: center center no-repeat url('/splash-cover.png');
  background-size: cover;
  color: #FFF;
`;

const Container = styled.div`
  max-width: 400px;
  margin:  200px auto;
  height: 300px;
  padding: 0 25px;
`;

const SignupBanner = () => (
  <Grid container>
    <Grid span={10}>
      <div style={{ marginTop: '15px' }}>
        <Typography variant="h5">Measure. Analyze. Optimize.</Typography>
      </div>
      <div style={{ marginTop: '15px' }}>
        <Typography variant="body1">Near real-time site performance at your fingertips.</Typography>
      </div>
      <div style={{ marginTop: '25px' }}>
        <Typography variant="body2">Coming soon. Can't wait? Send us a message at <strong>info@gas360.net</strong></Typography>
      </div>
    </Grid>
  </Grid>
);

const Header = () => (
  <Grid container justifyContent="center">
    <Grid item xs={6}>
      <LogoFull />
    </Grid>
  </Grid>
);

function Home() {
  return (
    <div className="App">
      <Main>
        <Container>
          <Header />
          <SignupBanner />
        </Container>
      </Main>
      <Grid container justifyContent="flex-end" style={{
        padding: '15px',
        position: 'absolute',
      }}>
        <Grid item>
          <Link to="/login" component={Button} variant="outlined">Signin</Link>
        </Grid>
      </Grid>
    </div>
  );
}

export default Home;
