import React from 'react';
import styled from '@emotion/styled';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TablePaginationUnstyled,
  TableRow,
  TableSortLabel,
} from '@mui/material';

const SlimTable = styled(Table)`
  border: none;
`;

const SlimTableCell = styled(TableCell)`
  font-size: 11px;
  line-height: 16px;
  height: auto;
`;

const SlimTableHeadCell = styled(SlimTableCell)`
`;

const PanelTable = ({ parent, header, rows }) => {
  return (
    <TableContainer sx={{ height: parent.height, overflow: 'auto' }}>
      <Table size="small" >
        <TableHead>
          <TableRow>
            {header.map((h, i, l) => (
              <SlimTableHeadCell
                key={i}
                align={i === (l.length - 1) ? 'right' : 'left'}
              >{h}</SlimTableHeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((r, i) => (
          <TableRow key={i}>
            {r.map((c, i, l) => (
              <SlimTableCell
                key={i}
                align={i === (l.length - 1) ? 'right' : 'left'}
              >{c}</SlimTableCell>
            ))}
          </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PanelTable;
