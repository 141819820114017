import React  from 'react';
import { useQuery } from '@apollo/react-hooks';
import Panel from './Panel';
import { ParentSize } from '@visx/responsive';
import {
  Grid,
  LinearProgress,
} from '@mui/material';
import TimeseriesSingleStat, { unitFormatter } from './TimeseriesSingleStat';
import { GET_FUEL_SALES } from './api';

const FuelQuantitySingleStat = ({
  siteId,
  startDate,
  endDate,
  interval,
}) => {
  const { loading, data = {} } = useQuery(GET_FUEL_SALES , {
    variables: {
      siteId,
      interval,
      startDate: startDate.toISODate(),
      endDate: endDate.toISODate(),
    },
  });

  const { site = {} } = data;
  const { fuelSales = {} } = site;
  const { quantity = 0, points = [] } = fuelSales;

  return (
    <Panel title="Fuel Sales" loading={loading}>
      <div>
        <Grid>
          <Grid desktop={12}>
            <div style={{ height: '300px' }}>
            {!loading && (
              <ParentSize>
              {parent => (
                <TimeseriesSingleStat
                  parent={parent}
                  startDate={startDate}
                  endDate={endDate}
                  interval={interval}
                  points={points}
                  total={quantity}
                  unitFormat={unitFormatter.gallons}
                />
              )}
              </ParentSize>
            )}
            </div>
          </Grid>
        </Grid>
      </div>
    </Panel>
  );
};

export default FuelQuantitySingleStat;
