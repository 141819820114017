import React  from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import styled from '@emotion/styled';
import {
  generatePath,
} from 'react-router';
import {
  Link,
  useParams,
  useLocation,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Grid,
  Typography,
  Tabs,
  Tab,
} from '@mui/material';
import Container from './Container';

const SiteHeaderContainer = styled(Container)`
  background-color: #FFF;
  border-bottom: 1px solid #E6E6E6;
`;

const GET_SITE = gql`
  query getSite($siteId: ID!) {
    site(id: $siteId) {
      id
      name
    }
  }
`;

const isDashboard = s => /\/dashboard$/.test(s);
const isUsers = s => /\/users$/.test(s);
const isSettings = s => /\/settings$/.test(s);

const tabCheck = [
  isDashboard,
  isUsers,
  isSettings,
];

const pageTitle = {
  0: 'Dashboard',
  1: 'Settings',
};

const SiteHeader = () => {
  const { id } = useParams();
  const location = useLocation();
  const { state = {} } = location;
  const tabValue = tabCheck.findIndex(fn => fn(location.pathname));
  const { loading: loadingGetSite, data: getSiteResult = {} } = useQuery(GET_SITE, {
    skip: state.site,
    variables: {
      siteId: id,
    },
  });

  const site = loadingGetSite ? {} : state.site || getSiteResult.site || {};

  return (
    <>
      <Helmet>
        <title>{site && site.name ? site.name : 'Site'} - {pageTitle[tabValue] || ''}</title>
      </Helmet>
      <SiteHeaderContainer>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography variant='h6' style={{ lineHeight: '48px' }}>{site.name}</Typography>
          </Grid>
          <Grid item container xs={12} sm={6} justifyContent="flex-end" alignItems="flex-end">
            <Grid item>
              <Tabs value={tabValue} aria-label="nav tabs example">
                <Tab label="Dashboard" to={generatePath('/sites/:id/dashboard', { id })} component={Link} />
                <Tab label="Users" to={generatePath('/sites/:id/users', { id })} component={Link} />
              </Tabs>
            </Grid>
          </Grid>
        </Grid>
      </SiteHeaderContainer>
    </>
  );
};

export default SiteHeader;
