import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Grid,
  Typography,
  Button,
  TextField,
} from '@mui/material';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const SUBMIT_CREATE_BRAND_INVITATION = gql`
  mutation createSiteInvitation($input: CreateSiteInvitationInput!) {
    createSiteInvitation(input: $input) {
      invitation {
        id
      }
    }
  }
`;

const CreateSiteInvitation = ({ onDone }) => {
  const { id } = useParams();
  const [submitCreateSiteInvitation, { data, loading, error }] = useMutation(SUBMIT_CREATE_BRAND_INVITATION);
  const [formState, setFormState] = useState({
    email: null,
  });

  const setStateProp = (prop) => (e) => {
    setFormState({
      ...formState,
      [prop]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    const input = {
      invitation: {
        ...formState,
        siteId: id,
      },
    };

    e.preventDefault();

    try {
      await submitCreateSiteInvitation({ variables: { input }});
      onDone();
    } catch (e) {
      throw e;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>User will see the invitation on the GAS360 dashboard.</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField outlined required fullWidth label="Email" type="text" onChange={setStateProp('email')} />
        </Grid>
        <Grid container item xs={12} justifyContent="flex-end">
          <Button variant="outlined" type="Submit">Send Invitation</Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateSiteInvitation;


