import gql from 'graphql-tag';

export const GET_MOP = gql`
  query methodOfPayments($siteId: ID!, $startDate: Date!, $endDate: Date!, $interval: TimeseriesInterval!) {
    site(id: $siteId) {
      methodOfPayments(
        startDate:$startDate
        endDate:$endDate
        interval:$interval
      ) {
        total
        quantity
        points {
          date
          metrics {
            name
            total
            quantity
          }
        }
      }
    }
  }
`;

export const GET_DEPT_SALES = gql`
  query departmentSales($siteId: ID!, $startDate: Date!, $endDate: Date!, $interval: TimeseriesInterval!) {
    site(id: $siteId) {
      departmentSales(
        startDate:$startDate
        endDate:$endDate
        interval:$interval
      ) {
        total
        quantity
        points {
          date
          metrics {
            name
            total
            quantity
          }
        }
      }
    }
  }
`;

export const GET_FUEL_SALES = gql`
  query fuelSales($siteId: ID!, $startDate: Date!, $endDate: Date!, $interval: TimeseriesInterval!) {
    site(id: $siteId) {
      fuelSales(
        startDate:$startDate
        endDate:$endDate
        interval:$interval
      ) {
        total
        quantity
        points {
          date
          metrics {
            name
            total
            quantity
          }
        }
      }
    }
  }
`;



export const GET_SITE = gql`
  query getSite($siteId: ID!) {
    site(id: $siteId) {
      id
      viewerPermissions
      invitations {
        edges {
          node {
            id
            email
            createdAt
          }
        }
      }
      users {
        edges {
          createdAt
          node {
            id
            givenName
            familyName
          }
        }
      }
    }
  }
`;
