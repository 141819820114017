import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { DateTime, Interval, FixedOffsetZone } from 'luxon';
import {
  Axis,
  BarStack,
  BarSeries,
  XYChart,
  Tooltip,
} from '@visx/xychart';

const formatDateForInterval = ({ interval, date }) => {
  const d = DateTime.fromISO(date, { zone: 'UTC' });

  if (interval === 'HOUR') {
    return d.toFormat('ha');
  } else if (interval === 'DAY') {
    return d.toFormat("MM'/'dd");
  } else if (interval === 'WEEK') {
    return d.toFormat("MM'/'dd");
  } else if (interval === 'MONTH') {
    return d.toFormat("LLL");
  }
};

const calcNumTicks = (w) => {
  return Math.floor(w/100);
};

const formatDateKeyForInterval = {
  HOUR: d => d.toISO(),
  DAY: d => d.toISODate(),
  WEEK: d => d.toISODate(),
  MONTH: d => d.toFormat('LL\'/\'yyyy'),
};

const groupPointsByTimeInterval = ({ points = [] }) => {
  const groups = points.reduce((o, p) => ({
    ...o,
    [p.date]: p.metrics
      ? p.metrics.reduce((oo, m) => ({
        ...oo,
        [m.name]: m,
      }), { date: p.date })
      : {},
  }), {});

  return Object.values(groups);
};

const pluckMetrics = R.pipe(
  R.pluck('metrics'),
  R.flatten,
  R.pluck('name'),
  R.uniq,
);

export const yAccessors = {
  value: R.pipe(
    R.prepend(R.__, ['total']),
    R.path,
  ),
  quantity: R.pipe(
    R.prepend(R.__, ['quantity']),
    R.pathOr(''),
  ),
};

export const unitFormatter = {
  money: (x = 0) => `$${x.toFixed(2)}`,
  gallons: (x = 0) => `${x.toFixed(2)} gal`,
};

const toolTipFormat = {
  'HOUR': 'FF',
  'DAY': 'DD',
  'WEEK': 'DD',
  'MONTH': 'LLLL',
};

const renderTooltip = ({ interval, yAccessor, unitFormat }) => ({ tooltipData, colorScale }) => {
  const value = yAccessor(tooltipData.nearestDatum.key)(tooltipData.nearestDatum.datum);
  if (!value) return;

  return (
    <div>
      <div style={{ color: colorScale(tooltipData.nearestDatum.key) }}>
      {tooltipData.nearestDatum.key}
      </div>
      {DateTime.fromISO(tooltipData.nearestDatum.datum.date, { zone: 'UTC' }).toFormat(toolTipFormat[interval])}
      {', '}
      {`${unitFormat(value)}`}
    </div>
  );
};

const TimeseriesXYChart = ({
  parent,
  points,
  startDate,
  endDate,
  interval,
  tz,
  yAccessor = yAccessors.value,
  tickFormat = R.identity,
  unitFormat = R.identity,
  yAxiesLabel,
}) => {
  const [metrics, setMetrics] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(groupPointsByTimeInterval({ points }));
    setMetrics(pluckMetrics(points));
  }, [points]);

  return (
    <XYChart
      height={parent.height}
      width={parent.width}
      xScale={{ type: 'band', paddingInner: 0.1 }}
      yScale={{ type: 'linear' }}
    >
      <Axis
        orientation="bottom"
        numTicks={calcNumTicks(parent.width)}
      />
      <Axis
        orientation="left"
        label={yAxiesLabel}
        tickFormat={tickFormat}
      />

      <BarStack order="descending">
        {metrics.map((metric) => (
          <BarSeries
            key={metric}
            data={data}
            dataKey={metric}
            xAccessor={o => formatDateForInterval({ interval, date: o.date })}
            yAccessor={yAccessor(metric)}
          />
        ))}
      </BarStack>

      <Tooltip
        snapTooltipToDatumX
        snapTooltipToDatumY
        showVerticalCrosshair
        showSeriesGlyphs
        renderTooltip={renderTooltip({ unitFormat, interval, yAccessor })}
      />
    </XYChart>
  )
};

export default TimeseriesXYChart;
