import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Grid,
  Typography,
  Button,
  TextField,
  Divider,
} from '@mui/material';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import styled from '@emotion/styled';
import CheckIcon from '@mui/icons-material/Check';

const ProductTier = styled.div`
  border-radius: 8px;
  border: 1px solid #DDD;
  padding: 25px;
`;

const SUBMIT_CREATE_SITE = gql`
  mutation createSite($input: CreateSiteInput!) {
    createSite(input: $input) {
      site {
        id
      }
    }
  }
`;

const CreateSite = () => {
  const [submitCreateSite, { data, loading, error }] = useMutation(SUBMIT_CREATE_SITE);
  const [formState, setFormState] = useState({
    name: null,
    streetAddress: null,
    addressLocality: null,
    addressRegion: null,
    postalCode: null,
  });

  const setStateProp = (prop) => (e) => {
    setFormState({
      ...formState,
      [prop]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    const input = {
      site: formState,
    };

    e.preventDefault();

    try {
      await submitCreateSite({ variables: { input }});
    } catch (e) {
      throw e;
    }
  };

  if (!loading && !error && data) {
    return <Redirect to={`/sites/${data.createSite.site.id}`} />
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={7}>
        <form onSubmit={handleSubmit} style={{ margin: '5px 0' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField type="text"
                fullWidth
                required
                onChange={setStateProp('name')}
                id="name"
                label="Name"
                helperText="Usually a store number. (e.g. ARCO 42026)"
                placeholder="Name" />
            </Grid>
            <Grid item xs={6}>
              <TextField type="text"
                fullWidth
                required
                onChange={setStateProp('streetAddress')}
                id="streetAddress"
                label="Street"
                placeholder="Street" />
            </Grid>
            <Grid item xs={6}>
              <TextField type="text"
                fullWidth
                required
                onChange={setStateProp('addressLocality')}
                id="addressLocality"
                label="City"
                placeholder="City" />
            </Grid>
            <Grid item xs={6}>
              <TextField type="text"
                fullWidth
                required
                onChange={setStateProp('addressRegion')}
                id="addressRegion"
                label="State"
                placeholder="State" />
            </Grid>
            <Grid item xs={6}>
              <TextField type="text"
                fullWidth
                required
                onChange={setStateProp('postalCode')}
                id="postalCode"
                label="Zip Code"
                placeholder="Zip" />
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end">
              <Grid item>
                <Button variant="contained" type="submit">Continue</Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid item sm={5} xs={12}>
        <ProductTier>
          <Typography variant="h5">$XX/month</Typography>
          <Typography variant="overline">30 day free trial</Typography>
          <Divider />
          <div style={{ marginTop: '20px' }}>
            <div>
              <Typography variant="body">
                <CheckIcon sx={{ verticalAlign: 'middle' }}/> Near real-time site performance
              </Typography>
            </div>
            <div>
              <Typography variant="body">
                <CheckIcon sx={{ verticalAlign: 'middle' }}/> Access anywhere, anytime.
              </Typography>
            </div>
            <div>
              <Typography variant="body">
                <CheckIcon sx={{ verticalAlign: 'middle' }}/> Daily reports (coming soon)
              </Typography>
            </div>
            <div>
              <Typography variant="body">
                <CheckIcon sx={{ verticalAlign: 'middle' }}/> 3 site users
              </Typography>
            </div>
          </div>
        </ProductTier>
      </Grid>
    </Grid>
  );
};

export default CreateSite;


