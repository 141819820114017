import React from 'react';
import styled from '@emotion/styled';
import {
  Typography,
  LinearProgress,
} from '@mui/material';

const PanelContainer = styled.div`
  background-color: #FFF;
  border-radius: 6px;
`;
const PanelHeader = styled.div`
  padding: 6px;
  text-align: center;
  border-radius: 6px 6px 0 0;
  border-top: 1px solid #333;
  border-left: 1px solid #333;
  border-right: 1px solid #333;
`;
const PanelContent = styled.div`
  text-align: center;
  border-radius: 0 0 6px 6px;
  border-left: 1px solid #333;
  border-right: 1px solid #333;
  border-bottom: 2px solid #222;
`;

const LoadingSpacer = styled.div`
height: 4px;
`;

const Panel = ({ title, children, loading }) => (
  <PanelContainer>
    <PanelHeader>
      <Typography use="subtitle2">{title}</Typography>
      { loading && <LinearProgress /> || <LoadingSpacer /> }
    </PanelHeader>
    <PanelContent>
      {children}
    </PanelContent>
  </PanelContainer>
);

export default Panel;
